<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style lang="scss">
@import "@/assets/base.css";
</style>
<script setup lang="ts"></script>
