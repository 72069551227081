<template>
  <div class="center-input-pages">
    <div class="title-text">争芳后台</div>
    <div
      class="input-box"
      v-show="defaultLoginType === 'qrcode' && !isBindStatus"
    >
      <div class="input-title">使用微信扫码登录</div>
      <div class="qr-code-box">
        <a-spin tip="Loading..." :spinning="spinningStatus">
          <div class="qr-code" id="wechat-qr-code"></div>
        </a-spin>
      </div>
      <div class="reset-qrcode" @click="qrCodeInit">重新获取二维码</div>
      <div class="switch-login-type" @click="handleChangeLoginType('qrcode')">
        使用手机号登录
      </div>
    </div>
    <div
      class="input-box"
      v-show="
        (defaultLoginType === 'phone' && !isBindStatus) ||
        (defaultLoginType === 'qrcode' && isBindStatus)
      "
    >
      <div class="input-title">
        {{ isBindStatus ? "绑定手机号" : "验证码登录" }}
      </div>
      <div class="input-phone">
        <input
          class="tel-input"
          type="tel"
          v-model="telephone"
          placeholder="请输入手机号"
        />
      </div>
      <div class="input-code">
        <input
          class="code-input"
          type="tel"
          v-model="telCode"
          maxlength="4"
          placeholder="输入短信验证码"
        />
        <div
          v-if="!isGetCodeStatus"
          class="code-btn"
          :class="telephone ? 'active-code-btn' : ''"
          @click="handleGetCode"
        >
          获取短信验证码
        </div>
        <div v-else class="code-reset">重新获取{{ resetCodeTime }}s</div>
      </div>
      <div
        class="login-btn"
        :class="telCode ? 'active-btn' : ''"
        @click="handleUserLogin"
      >
        {{ isBindStatus ? "绑定" : "登录" }}
      </div>
      <div class="switch-login-type" @click="handleChangeLoginType('phone')">
        使用微信二维码登录
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { getCurrentInstance, onMounted, ref } from "vue";
import {
  getUserPhoneCode,
  getWeChartConfigInfo,
  postWechatLoginByCode,
  userLoginGetInfo
} from "@/views/Login/_apis";
import { hex_md5 } from "@/utils/md5";
import { checkMobile, getUrlParams } from "@/utils";
import { useRouter } from "vue-router";
import { CSS_STYLE_BY_SCAN_QRCODE } from "@/views/Login/_data";
const Message =
  getCurrentInstance()?.appContext.config.globalProperties.$message;

/** 导航跳转 */
const Router = useRouter();
/** 默认登录方式 */
const defaultLoginType = ref<"qrcode" | "phone">("qrcode");
/** 是否绑定 */
const isBindStatus = ref<boolean>(false);
/** 是否已经获取了验证码 */
let isGetCodeStatus = ref<boolean>(false);
/** 重新获取验证码倒计时实例 */
let countdownResetCodeEl = ref<any>();
/** 手机号 */
const telephone = ref<string>();
/** 手机验证码 */
const telCode = ref<string>();
/** 重新获取验证码 */
const resetCodeTime = ref<number>(60);
/** 加载状态 */
const spinningStatus = ref(true);
/** 用户openId */
const openId = ref<string>("");
/** 用户unionId */
const unionId = ref<string>("");
/** 用户Token */
const accessToken = ref<string>("");
/** 用户省份 */
const province = ref<string>("");
/** 用户地区 */
const city = ref<string>("");
/** 需要绑定的状态 */
const needBindStatus = ref<string>("0");
/** 重定向地址 */
const redirectUrl = ref<string>("");

/** 二维码登录初始化 */
const qrCodeInit = async () => {
  spinningStatus.value = true;
  const res = await getWeChartConfigInfo();
  if (res.status !== "200") return;
  const configInfo = res.data;
  redirectUrl.value = configInfo.redirect_uri;
  new WxLogin({
    self_redirect: false,
    id: "wechat-qr-code",
    // appid: "wx2349bc768b04c2d9",
    appid: configInfo.appid,
    scope: configInfo.scope,
    // scope: "snsapi_login",
    redirect_uri: configInfo.redirect_uri,
    // redirect_uri: "https://test-zf.douquan.com",
    state: new Date().getTime(),
    style: "",
    href: CSS_STYLE_BY_SCAN_QRCODE
  });
  setTimeout(() => {
    spinningStatus.value = false;
  }, 1000);
};

/** 倒计时 */
const countdownResetCode = () => {
  countdownResetCodeEl.value = setInterval(() => {
    if (resetCodeTime.value) {
      resetCodeTime.value--;
    } else {
      isGetCodeStatus.value = false;
      resetCodeTime.value = 60;
      clearInterval(countdownResetCodeEl.value);
    }
  }, 1000);
};

/** 获取验证码 */
const handleGetCode = async () => {
  if (!telephone.value) return Message.warning("请先输入手机号");
  if (!checkMobile(telephone.value)) {
    return Message.warning("请输入正确的手机号");
  }
  localStorage.removeItem("token");
  const res = await getUserPhoneCode({
    phone: telephone.value,
    validateSign: hex_md5(telephone.value + "DQ6ODEU9#5!90")
  });
  if (res.status !== "200") return;
  Message.info(res.message || "验证码已发送");
  isGetCodeStatus.value = true;
  countdownResetCode();
};

/** 用户登录 */
const handleUserLogin = async () => {
  if (!telephone.value) return Message.warning("请先输入手机号");
  if (!checkMobile(telephone.value)) {
    return Message.warning("请输入正确的手机号");
  }
  if (!telCode.value) return Message.warning("请输入验证码");
  let tempParams = {};
  if (isBindStatus.value) {
    tempParams = {
      unionId: unionId.value,
      openId: openId.value,
      accessToken: accessToken.value,
      province: province.value,
      city: city.value,
      needBindVX: needBindStatus.value
    };
  }
  const res = await userLoginGetInfo({
    phone: telephone.value,
    code: telCode.value,
    ...tempParams
  });
  if (res.status !== "200") return;
  localStorage.setItem("token", res.data.tokenValue);
  localStorage.setItem("userInfo", JSON.stringify(res.data));
  if (redirectUrl.value) {
    window.location.href = redirectUrl.value + "/#/home";
  } else {
    await Router.push({
      path: "/home"
    });
  }
};

/** 切换默认登录方式 */
const handleChangeLoginType = (type: "qrcode" | "phone") => {
  if (type === "qrcode") {
    defaultLoginType.value = "phone";
  } else if (type === "phone") {
    qrCodeInit();
    defaultLoginType.value = "qrcode";
  }
};

/** 微信登录 */
const wechatLoginByCode = async (code: string) => {
  const res = await postWechatLoginByCode({ vxCode: code });
  if (res.status !== "200") return;
  if (Number(res.data.status)) {
    localStorage.setItem("token", res.data.tokenValue);
    localStorage.setItem("userInfo", JSON.stringify(res.data));
    window.location.href = redirectUrl.value + "/#/home";
    // await Router.push({
    //   path: "/home"
    // });
  } else {
    isBindStatus.value = true;
    openId.value = res.data.openId;
    unionId.value = res.data.unionId;
    accessToken.value = res.data.accessToken;
    province.value = res.data.province;
    city.value = res.data.city;
    needBindStatus.value = "1";
  }
};

/** 初始化 */
onMounted(async () => {
  try {
    const code = getUrlParams("code");
    const state = getUrlParams("state");
    if (state && code) {
      await wechatLoginByCode(code);
    } else {
      if (defaultLoginType.value === "qrcode") {
        await qrCodeInit();
      }
    }
  } catch (e) {
    console.log(e, "获取错误");
  }
});
</script>

<style lang="scss" scoped>
.reset-qrcode {
  margin-top: 20px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #32788a;
  font-weight: 400;
  cursor: pointer;
}
.switch-login-type {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
  color: #32788a;
  font-weight: 400;
  cursor: pointer;
}
.login-btn {
  margin-top: 32px;
  width: 100%;
  height: 43px;
  background: #f2f2f2;
  border-radius: 1px;
  border: 1px solid #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 13px;
  color: #c0c0c0;
  cursor: pointer;
}
.active-btn {
  background: #32788a !important;
  color: #fff !important;
}
.code-reset {
  font-weight: 400;
  font-size: 13px;
  color: #9b9b9b;
  line-height: 16px;
}
.code-btn {
  font-weight: 400;
  font-size: 13px;
  color: #d9d9d9;
  line-height: 16px;
  cursor: pointer;
}
.active-code-btn {
  color: #32788a !important;
  cursor: pointer;
}
.input-code {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  input {
    width: calc(100% - 150px);
    height: 45px;
    font-weight: 400;
    font-size: 13px;
    color: #2c2c2c;
    line-height: 16px;
  }
}
.input-phone {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  input {
    width: 100%;
    height: 45px;
    font-weight: 400;
    font-size: 13px;
    color: #2c2c2c;
    line-height: 16px;
  }
}
.input-title {
  font-weight: bold;
  font-size: 18px;
  color: #2c2c2c;
}
.input-box {
  padding: 18px;
  margin-top: 25px;
  width: 100%;
  background: #ffffff;
  border-radius: 7px;
}
.center-input-pages {
  position: relative;
  z-index: 10;
  width: 377px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title-text {
  text-align: center;
  font-weight: bold;
  font-size: 34px;
  color: #fff;
  line-height: 47px;
}
input::placeholder {
  font-weight: 400;
  font-size: 13px;
  color: #d9d9d9;
  line-height: 16px;
}
.qr-code-box {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .qr-code {
    width: 200px;
    height: 200px;
    background-color: #9b9b9b;
    text-align: center;
  }
}
.qr-code:deep(iframe) {
  width: 200px !important;
  height: 200px !important;
}
</style>
