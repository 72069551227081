/****   request.js   ****/
// 导入axios
import axios from "axios";
// 使用ant design Message做消息提醒
import { message } from "ant-design-vue";
//1. 创建新的axios实例，
const service = axios.create({
  // 超时时间 单位是ms，这里设置了3s的超时时间
  timeout: 3 * 1000
});
// 2.请求拦截器
service.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token") || "";
    const proxy_url = process.env.VUE_APP_BASE_URL;
    config.url = proxy_url + config.url;
    config.headers["token"] = token || "";
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 3.响应拦截器
service.interceptors.response.use(
  (res) => {
    if (res.status === 200) {
      const { status } = res.data;
      if (
        status === 401 ||
        status === "401" ||
        status === "405" ||
        status === "405"
      ) {
        localStorage.removeItem("token");
        setTimeout(() => location.reload(), 500);
      }
      if (status === "SUCCESS" || status === 200 || status === "200") {
        return res.data;
      } else {
        message.error(res.data.message || "响应异常，联系技术人员");
        return res.data;
      }
    } else {
      return message.error("服务异常，请联系技术人员");
    }
  },
  (err) => {
    if (err && err.response) {
      if (
        err.response.status === 401 ||
        err.response.status === "401" ||
        err.response.status === 405 ||
        err.response.status === "405"
      ) {
        message.error("TOKEN过期");
        localStorage.removeItem("TOKEN");
        setTimeout(() => location.reload(), 500);
      } else {
        return message.error("服务异常，联系技术人员", err);
      }
    } else {
      return message.error("服务异常，联系技术人员", err);
    }
  }
);
export default service;
