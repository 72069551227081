import { RouteRecordRaw } from "vue-router";

export default [
  {
    path: "/publishProductPage",
    name: "publishProductPage",
    meta: { title: "发布管理" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../../views/ContentPage/PublishProductAbout/index.vue"
      )
  }
] as Array<RouteRecordRaw>;
