/** 根据登录环境，微信扫码重定向地址 */
export const REDIRECT_URL_BY_SCAN_WECHAT_QRCODE = {
  local: "https://test-zf.douquan.com",
  test: "https://test-zf.douquan.com",
  production: "https://zf.douquan.com"
};

/** 微信二维码样式base64加密 */
export const CSS_STYLE_BY_SCAN_QRCODE =
  "data:text/css;base64,QGNoYXJzZXQgIlVURi04IjsKLmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDE5OHB4O2hlaWdodDogMTk4cHg7bWFyZ2luLXRvcDowfQouaW1wb3dlckJveCAudGl0bGUge2Rpc3BsYXk6bm9uZTt9Ci5pbXBvd2VyQm94IC5pbmZvIHtwb3NpdGlvbjogYWJzb2x1dGU7dG9wOiAwO2xlZnQ6IDA7d2lkdGg6IDIwMHB4O2hlaWdodDogMjAwcHg7fQouaW1wb3dlckJveCAuc3RhdHVzX3R4dCB7ZGlzcGxheTpub25lO30KLmltcG93ZXJCb3ggLnN0YXR1cy5zdGF0dXNfYnJvd3NlciB7ZGlzcGxheTpub25lO30KLmltcG93ZXJCb3ggLnN0YXR1cyB7cGFkZGluZzowO3dpZHRoOjIwMHB4O2hlaWdodDoyMDBweDtiYWNrZ3JvdW5kOiByZ2JhKDI1NSwgMjU1LCAyNTUsIDAuOCk7ZGlzcGxheTogZmxleDtqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjthbGlnbi1pdGVtczogY2VudGVyO30gCi5pbXBvd2VyQm94IC5zdGF0dXMgcHtkaXNwbGF5Om5vbmU7fQouaW1wb3dlckJveHt3aWR0aDogMjAwcHg7aGVpZ2h0OiAyMDBweH0=";
