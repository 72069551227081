import { RouteRecordRaw } from "vue-router";

export default [
  {
    path: "/sendProductListPage",
    name: "sendProductListPage",
    meta: { title: "发货列表" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../../views/ContentPage/SendProductAbout/index.vue"
      )
  },
  {
    path: "/sendPrintPage",
    name: "sendPrintPage",
    meta: { title: "电子面单" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../../views/ContentPage/SendProductSettingAbout/_otherPage/SendPrint/index.vue"
      )
  },
  {
    path: "/sendAddressPage",
    name: "sendAddressPage",
    meta: { title: "发货地址" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../../views/ContentPage/SendProductSettingAbout/_otherPage/SendAddress/index.vue"
      )
  }
] as Array<RouteRecordRaw>;
