<template>
  <div class="login-pages">
    <!--  顶部logo  -->
    <TopLogin />
    <!--  中部登录信息  -->
    <div class="center-box">
      <CenterInput />
    </div>
    <!--  图片  -->
    <img class="img-left-top" :src="icons.img_login_left_top" alt="" />
    <img class="img_right_bottom" :src="icons.img_login_right_bottom" alt="" />
  </div>
</template>

<script lang="ts" setup>
import TopLogin from "@/views/Login/_components/TopLogo/inde.vue";
import CenterInput from "@/views/Login/_components/CenterInput/index.vue";
import { reactive } from "vue";

/** 图片 */
const icons = reactive({
  img_login_left_top: require("./_static/img_login_left_top.png"),
  img_login_right_bottom: require("./_static/img_login_right_bottom.png")
});
</script>

<style lang="scss" scoped>
.center-box {
  //position: absolute;
  //width: 100%;
  //left: 50%;
  //top: 50%;
  width: 100%;
  min-height: calc(100vh - 63px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-pages {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: #32788a;
}
.img-left-top {
  position: absolute;
  top: 63px;
  left: 0;
  width: 570px;
  height: 159px;
}
.img_right_bottom {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 608px;
  height: 194px;
}
</style>
