import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Login from "../views/Login/index.vue";
import RouterMap from "@/router/routerMap";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "login",
    meta: { title: "争芳后台" },
    component: Login
  },
  {
    path: "/home",
    name: "home",
    meta: { title: "争芳后台" },
    children: RouterMap,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Home/index.vue")
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  linkActiveClass: "active"
});

router.beforeEach((to, from, next) => {
  window.document.title =
    to.meta.title == undefined ? "斗泉运营管理后台" : String(to.meta.title);
  if (to.path === "/") {
    next();
  } else {
    //此处可以做一些逻辑判断，例如权限，token验证
    const token = localStorage.getItem("token");
    if (!token) {
      next("/");
    } else {
      next();
    }
  }
});

export default router;
