// eslint-disable-next-line
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { useLoading } from "@/components/UseLoading";
import Print from "vue3-print-nb";
import {
  Button,
  message,
  Menu,
  Breadcrumb,
  Tabs,
  Select,
  Input,
  DatePicker,
  Table,
  Image,
  Tag,
  Modal,
  Popconfirm,
  QRCode,
  TimePicker,
  Cascader,
  Drawer,
  Row,
  Col,
  Upload,
  Progress,
  Radio,
  Spin,
  Form,
  Divider,
  Switch,
  Timeline,
  Popover,
} from "ant-design-vue";
const app = createApp(App);

app.config.globalProperties.$message = message;

// 提供全局的加载中状态
app.provide("loadingState", useLoading());

// 全局注册Spin组件，可以在任何组件中直接使用
app.component("a-spin", Spin);

app.use(Button);
app.use(Popover);
app.use(Timeline);
app.use(Switch);
app.use(Divider);
app.use(Form);
app.use(Radio);
app.use(Progress);
app.use(Upload);
app.use(Row);
app.use(Col);
app.use(Drawer);
app.use(Cascader);
app.use(TimePicker);
app.use(QRCode);
app.use(Popconfirm);
app.use(Modal);
app.use(Tag);
app.use(Image);
app.use(Table);
app.use(DatePicker);
app.use(Input);
app.use(Select);
app.use(Tabs);
app.use(Breadcrumb);
app.use(Menu);
app.use(store);
app.use(router);
app.use(Print);
app.mount("#app");
