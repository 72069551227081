<template>
  <div class="top-logo-pages">
    <div class="logo-icon">
      <img :src="logoIcon.logoIcon" alt="" />
    </div>
    <div class="logo-text">争芳后台</div>
  </div>
</template>

<script lang="ts" setup>
import { reactive } from "vue";

/** 图片 */
const logoIcon = reactive({
  logoIcon: require("../../_static/logo.png")
});
</script>

<style lang="scss" scoped>
.logo-text {
  margin-left: 17px;
  font-weight: bold;
  font-size: 18px;
  color: #2c2c2c;
}
.logo-icon {
  margin-left: 25px;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 36px;
    height: 36px;
  }
}
.top-logo-pages {
  width: 100%;
  height: 63px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
}
</style>
