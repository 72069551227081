import { ListColumns } from "@/assets/_baseInter";

/** ant 下拉选择过滤 */
export const filterOption = (e: any, option: any) => {
  const in_array = option.componentOptions.children;
  if (in_array[0].children && in_array[0].children.length > 0) {
    const { attrs } = in_array[0].children[1].data;
    return attrs.text.toLowerCase().indexOf(e.toLowerCase().trim()) >= 0;
  } else {
    return in_array[0].text.toLowerCase().indexOf(e.toLowerCase().trim()) >= 0;
  }
};

/**
 * @param objAttr 需要转化成的对象的对应数组中的属性值
 * @param objValue 需要转化成对象的值所对应数组中的值
 * @param objArr  需要转化的对象数组
 * */
interface ObjArrParams {
  name: string | number;
  value: string | number;
  [key: string]: string | number;
}
interface TempParams {
  [key: string]: string | number;
}
export const objArrMapObj = (
  objAttr: string,
  objValue: string,
  objArr: ObjArrParams[]
) => {
  const obj: TempParams = {};
  objArr.forEach((el) => {
    obj[el[objAttr]] = el[objValue];
  });
  return obj;
};

/** 判断正确的手机号 */
//JS使用正则表达式校验电话号码
export const checkMobile = (mobile: string) => {
  const re = /^1[3,4,5,6,7,8,9][0-9]{9}$/;
  return re.test(mobile);
};

/** 判断对象的全部属性是否为空数组 */
export const areAllValuesEmptyArrays = (obj: ListColumns) => {
  return Object.values(obj).every(
    (value) => Array.isArray(value) && value.length === 0
  );
};

/** 时间戳转换年月日 */
export const timestampToDate = (timestamp: number | string) => {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2); // 月份是从0开始的
  const day = ("0" + date.getDate()).slice(-2);
  return year + "-" + month + "-" + day;
};

/** 下载图片 */
export const convertToBase64 = async (imgUrl: string) => {
  try {
    const response = await fetch(imgUrl);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const blob = await response.blob();
    const objectUrl = URL.createObjectURL(blob);
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx?.drawImage(img, 0, 0, img.width, img.height);
      const base64 = canvas.toDataURL("image/png");
      URL.revokeObjectURL(objectUrl); // 释放内存
      return base64;
    };
    img.src = objectUrl;
  } catch (error) {
    return false;
  }
};

/** 获取路径上的参数 */
export const getUrlParams = (key: string) => {
  const search = window.location.search.replace(/^\?/, "");
  const pairs = search.split("&");
  const paramsMap = pairs
    .map((pair) => {
      const [key, value] = pair.split("=");
      return [decodeURIComponent(key), decodeURIComponent(value)];
  }).reduce((res, [key, value]) => Object.assign(res, { [key]: value }), {});
  return paramsMap[key] || "";
};
