import service from "@/request";
import { ApiReturnData } from "@/request/apiInter";

/** 用户登录 */
export const userLoginGetInfo = (params: {
  phone: string;
  code: string;
  openId?: string;
  unionId?: string;
  accessToken?: string;
  province?: string;
  city?: string;
  needBindVX?: string;
}): Promise<ApiReturnData> => {
  return service({
    url: "/apipc/user/login",
    method: "post",
    data: params
  });
};
/** 用户获取验证码 */
export const getUserPhoneCode = (params: {
  phone: string;
  validateSign: string;
}): Promise<ApiReturnData> => {
  return service({
    url: "/apipc/user/sendSms",
    method: "get",
    params: params
  });
};

/** 获取二维码登录微信配置信息 */
export const getWeChartConfigInfo = (): Promise<ApiReturnData> => {
  return service({
    url: "/apipc/user/loginParam",
    method: "post"
  });
};

/** 获取二维码登录微信配置信息 */
export const postWechatLoginByCode = (params: {
  vxCode: string;
}): Promise<ApiReturnData> => {
  return service({
    url: "/apipc/user/loginByWX",
    method: "post",
    data: params
  });
};
